import { IFooterRow } from 'src/app/models/footer.models';

export const footerData: IFooterRow[] = [
  {
    label: 'API',
    path: '/api',
    links: [
      {
        label: 'Payments V1',
        path: '/api/payments',
      },
      {
        label: 'Payments V2',
        path: '/api/payments/v2',
      },
      {
        label: 'Payments V3',
        path: '/api/payments/v3',
      },
      {
        label: 'Payment Links',
        path: '/api/links',
      },
      {
        label: 'Express Widgets',
        path: '/api/widgets',
      },
      {
        label: 'Products',
        path: '/api/products',
      },
    ],
  },
  {
    label: 'Shopsystems',
    path: '/shopsystems',
    links: [
      {
        label: 'Shopify',
        href: 'https://support.payever.org/hc/en-us/articles/6095452219154-Shopify',
      },
      {
        label: 'Prestashop',
        href: 'https://support.payever.org/hc/en-us/articles/360023894934-Prestashop',
      },
      {
        label: 'Plentymarkets',
        href: 'https://support.payever.org/hc/en-us/articles/360024069353-Plentymarkets',
      },
      {
        label: 'Shopware 5',
        href: 'https://support.payever.org/hc/en-us/articles/360023894874-Shopware-5',
      },
      {
        label: 'Shopware 6',
        href: 'https://support.payever.org/hc/en-us/articles/360012435820-Shopware-6',
      },
      {
        label: 'WooCommerce',
        href: 'https://support.payever.org/hc/en-us/articles/360024065033-WooCommerce',
      },
      {
        label: 'xt:Commerce',
        href: 'https://support.payever.org/hc/en-us/articles/360024069633-xt-Commerce',
      },
      {
        label: 'JTL 4',
        href: 'https://support.payever.org/hc/en-us/articles/360023894914-JTL-4',
      },
      {
        label: 'JTL 5',
        href: 'https://support.payever.org/hc/en-us/articles/360019358219-JTL-5',
      },
      {
        label: 'CCV Shop',
        href: 'https://support.payever.org/hc/en-us/articles/6106203706140-CCV-Shop',
      }, {
        label: 'JTL 4',
        href: 'https://support.payever.org/hc/en-us/articles/360023894914-JTL-4',
      },
      {
        label: 'JTL 5',
        href: 'https://support.payever.org/hc/en-us/articles/360019358219-JTL-5',
      },
      {
        label: 'OXID',
        href: 'https://support.payever.org/hc/en-us/articles/360023900334-OXID',
      },
      {
        label: 'Magento 1',
        href: 'https://support.payever.org/hc/en-us/articles/360023894894-Magento-1',
      },
      {
        label: 'Magento 2',
        href: 'https://support.payever.org/hc/en-us/articles/360012044440-Magento-2',
      },
      {
        label: 'Opencart',
        href: 'https://support.payever.org/',
      },
      {
        label: 'DanDomain',
        href: 'https://support.payever.org/hc/en-us/articles/360011957119-DanDomain-Payment-API-integration',
      },
      {
        label: 'Commercetools',
        href: 'https://support.payever.org/hc/en-us/articles/6699064172316-Commercetools',
      },
      {
        label: 'CCV Shop',
        href: 'https://support.payever.org/hc/en-us/articles/6106203706140-CCV-Shop',
      },
      {
        label: 'Orocommerce',
        href: 'https://support.payever.org/hc/en-us/articles/10225059967260-OroCommerce',
      },
      {
        label: 'Smartstore',
        href: 'https://support.payever.org/hc/en-us/articles/12812230024604-Smartstore',
      },
    ],
  },
  {
    label: 'Payments',
    path: '/payments',
    links: [
      {
        label: 'Ivy',
        path: '/payments/de/ivy',
      },
      {
        label: 'Santander Installments',
        path: '/payments/de/santander-installments',
      },
      // {
      //   label: 'Santander Splitpay',
      //   path: '/payments/de/santander-factoring',
      // },
      // {
      //   label: 'Santander BNPL',
      //   path: '/payments/de/santander-invoice',
      // },
      {
        label: 'Instant payments',
        path: '/payments/de/direktuberweisung',
      },
      {
        label: 'Stripe Credit Card',
        path: '/payments/de/stripe-cc',
      },
      {
        label: 'Stripe Direct Debit',
        path: '/payments/de/stripe-dd',
      },
      // {
      //   label: 'Sofort',
      //   path: '/payments/de/sofort',
      // },
      {
        label: 'PayPal',
        path: '/payments/de/paypal',
      },
      {
        label: 'Swedbank Pay CC',
        path: '/payments/se/payex-credit-card',
      },
      {
        label: 'Swedbank Pay Invoice',
        path: '/payments/se/payex-credit-card',
      },
      {
        label: 'Wiretransfer',
        path: '/payments/de/wiretransfer',
      },
      {
        label: 'Zinia BNPL',
        path: '/payments/de/zinia-bnpl',
      },
      {
        label: 'Zinia Splitpay',
        path: '/payments/de/zinia-installments',
      },
      {
        label: 'Allianz Trade pay',
        path: '/payments/de/allianz-trade-pay',
      },
    ],
  },
  {
    label: 'Point of Sale',
    path: '/point-of-sale',
    excludedCountries: ['no', 'dk'],
    links: [
      {
        label: 'Santander Installments',
        path: '/point-of-sale/santander-installments',
      },
      {
        label: 'Zinia BNPL',
        path: '/payments/de/zinia-bnpl',
      },
      {
        label: 'Zinia Splitpay',
        path: '/payments/de/zinia-installments',
      },
      // {
      //   label: 'Santander Invoice',
      //   path: '/point-of-sale/santander-invoice',
      // },
      // {
      //   label: 'Santander Splitpay',
      //   path: '/point-of-sale/santander-factoring',
      // },            
    ],
  },
  /*{
    label: 'Platform',
    path: '/',
    links: [
      {
        label: 'Products',
        path: '/platform/products/api',
      }
    ],
  },*/
  {
    label: 'Resources',
    path: '/resources',
    links: [
      {
        label: 'Test Credentials',
        path: '/resources/de/test-credentials',
      },
      {
        label: 'API References',
        path: '/resources/references',
      },
    ],
  },
  {
    label: 'Support',
    path: '/support',
    links: [
      {
        label: 'Bug Reporting',
        href: 'https://support.payever.org/hc/en-us',
      },
      {
        label: 'Contact Us',
        href: 'https://support.payever.org/hc/en-us/requests/new',
      },
    ],
  },
  {
    label: 'Account',
    path: '/account',
    links: [
      {
        label: 'Authentification',
        path: '/account/authentication',
      }
    ],
  },
];
