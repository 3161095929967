import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MarkdownScrollService } from './../../services/markdown-scroll/markdown-scroll.service';

@Component({
  selector: 'app-sidebar-navigation',
  templateUrl: './sidebar-navigation.component.html',
  styleUrls: ['./sidebar-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarNavigationComponent {
  @Input() folded: boolean;
  @Output() toggled = new EventEmitter();

  constructor(private markdownScrollService: MarkdownScrollService) {}

  get menu$() {
    return this.markdownScrollService.orderedMenu$;
  }

  get activeSubSection$() {
    return this.markdownScrollService.activeSubsection$;
  }

  public onSelected(id: string) {
    this.markdownScrollService.scrollTo(id);
  }

  public onToggleFoldedSidebar() {
    this.toggled.emit();
  }

  public toggleExpand(id: string) {
    this.markdownScrollService.toggleExpand(id);
  }

  trackItems(i, item): string | number {
    return item.id || i;
  }
}
