export enum CountryEnum {
  de = 'de',
  no = 'no',
  se = 'se',
  dk = 'dk',
  at = 'at',
  uk = 'uk',
  nl = 'nl',
  fi = 'fi'
}
