import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { CountrySelectorService } from '../../services/country-selector/country-selector.service';
import { CountryEnum } from './../../models/countries.models';

@Component({
  selector: 'app-country-selector',
  templateUrl: './country-selector.component.html',
  styleUrls: ['./country-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountrySelectorComponent implements OnDestroy, OnInit {
  countries: string[] = Object.keys(CountryEnum);
  countryNames = {
    [CountryEnum.de]: 'Germany',
    [CountryEnum.fi]: 'Finland',
    [CountryEnum.nl]: 'Netherlands',
    [CountryEnum.no]: 'Norway',
    [CountryEnum.se]: 'Sweden',
    [CountryEnum.dk]: 'Denmark',
    [CountryEnum.at]: 'Austria',
    [CountryEnum.uk]: 'United Kingdom',
  };

  constructor(private countrySelectorService: CountrySelectorService, private changeDetector: ChangeDetectorRef) {
  }

  get country$(): Observable<CountryEnum> {
    return this.countrySelectorService.country$;
  }

  get mobileCountry$(): Observable<string> {
    return this.countrySelectorService.country$.pipe(
      map(country =>
        this.countries.find(key => key === country)
      )
    );
  }

  get isHidden$(): Observable<boolean> {
    return this.countrySelectorService.isHidden$;
  }

  public onSelected(country: CountryEnum) {
    this.countrySelectorService.change(country);
  }

  ngOnInit(): void {
    this.countrySelectorService.countryList$.pipe(untilDestroyed(this)).subscribe((countries: string[]) => {
      this.countries = countries;
      this.changeDetector.detectChanges();
    });
  }

  ngOnDestroy() {}
}
