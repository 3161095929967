import { Component, OnInit } from '@angular/core';
import {BreakpointObserver} from "@angular/cdk/layout";

@Component({
  selector: 'app-questions-bar',
  templateUrl: './questions-bar.component.html',
  styleUrls: ['./questions-bar.component.scss']
})
export class QuestionsBarComponent implements OnInit {
  isMobile: boolean = this.breakpointObserver.isMatched('(max-width: 520px)');

  constructor(private breakpointObserver: BreakpointObserver) { }

  ngOnInit() {
  }

  showZendesk() {
    if (window['zE'] && window['zE'].activate) {
      window['zE'].activate();
      if (this.isMobile) { // fix for losing scroll position on ios
        const scrollPosition = window.scrollY;
        setTimeout(() => {
          window.scrollTo(0, scrollPosition);
        }, 1500);
      }

    } else { // if clicked before script loaded
      setTimeout(() => {
        this.showZendesk();
      }, 1000);
    }
  }
}
