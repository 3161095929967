import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ICardBlock } from 'src/app/models/card-blocks.models';
import {IPoint} from "../../models/points.models";

@Component({
  selector: 'app-account-container',
  templateUrl: './account-container.component.html',
  styleUrls: ['./account-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountContainerComponent implements OnInit {
  heading: string = 'About payever Account';
  title: string = 'Start using payever';
  description: string = 'Register today and start selling and growing your business with payever Payments and Integrations.';

  points: IPoint[] = [
    {
      title: 'Register',
      description: 'Go through payever sign-up to create an account.',
    },
    {
      title: 'Two-Factor Authentication',
      description: 'Ensure your security with enabling 2FA.',
    },
    {
      title: 'Apps',
      description: 'Gain access to a variety of apps and integrations for your business.',
    },
  ];

  public links: ICardBlock[] = [
    {
      title: 'Authentication',
      path: '/account/authentication',
      icon: 'assets/img/account/user-check.svg',
    }
  ];

  constructor() {}

  ngOnInit() {}
}
