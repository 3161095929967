import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { CountrySelectorService } from '../../services/country-selector/country-selector.service';

@Component({
  selector: 'app-landing-container',
  templateUrl: './landing-container.component.html',
  styleUrls: ['./landing-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LandingContainerComponent implements OnDestroy {
  countryKey: string;

  constructor(public countrySelectorService: CountrySelectorService) {}

  ngOnInit() {
    this.countrySelectorService.country$.pipe((untilDestroyed(this))).subscribe(country => {
      this.countryKey = country;
    });
  }

  ngOnDestroy() {}
}
