import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, EventEmitter, HostListener, Inject, Input, OnChanges, Output, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[appScrollSpy]',
})
export class ScrollSpyDirective implements OnChanges, AfterViewInit {
  @Input() src: string;
  @Output() sectionChange = new EventEmitter<string>();
  @Output() subSectionChange = new EventEmitter<string>();
  private currentSubChapter: string;
  private sections: HTMLElement[] = [];
  private subSections: HTMLElement[] = [];
  private currentSection: string;

  constructor(private elementRef: ElementRef, @Inject(DOCUMENT) private document: Document) {}

  ngOnChanges() {
    this.sections = [];
    setTimeout(_ => this.initSections(), 1000);
  }

  ngAfterViewInit() {
    setTimeout(_ => {
      this.initSections();
      if (!this.document.documentElement.scrollTop) window.scroll(1, 1); // TODO: Fix this. sections are empty till scroll.
    }, 1000);
  }

  private initSections() {
    this.sections = [];
    this.subSections = [];
    const children: HTMLElement[] = this.elementRef.nativeElement.children;
    this.sections = Array.from(children).filter((item: HTMLElement) => {
      return item.tagName === 'SECTION';
    });

    this.subSections = Array.from(children).filter((item: HTMLElement) => {
      return item.tagName === 'H2';
    });
  }

  @HostListener('document:scroll', ['$event'])
  onScroll() {
    let currentSubChapter: string;
    let currentSection: string;
    const scrollTop = this.document.documentElement.scrollTop;
    const parentOffset = this.document.documentElement.offsetTop;

    if (this.sections.length) {
      this.sections.forEach(section => {
        if (section.offsetTop - parentOffset < scrollTop + 100) {
          currentSection = section.id;
        }
      });

      this.subSections.forEach(subSection => {
        if (subSection.offsetTop - parentOffset < scrollTop + 100) {
          currentSubChapter = subSection.id;
        }
      });
    } else {
      this.initSections();
    }

    if (currentSection !== this.currentSection) {
      this.currentSection = currentSection;
      this.sectionChange.emit(this.currentSection);
    }

    if (!currentSubChapter || (currentSubChapter !== this.currentSubChapter && currentSubChapter.split('_')[0] === this.currentSection)) {
      this.currentSubChapter = currentSubChapter;
      this.sectionChange.emit(this.currentSubChapter);
    }
  }
}
