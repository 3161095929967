import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'app-landing-block',
  templateUrl: './landing-block.component.html',
  styleUrls: ['./landing-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LandingBlockComponent implements OnInit {
  @Input() title: string;
  @Input() label: string;
  @Input() icon: string;
  @Input() backgroundColor: 'light-grey' | 'grey' | 'white' = 'white';

  constructor() {}

  ngOnInit() {}
}
