import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { data } from './uptime-graphs.data';

@Component({
  selector: 'app-uptime-graphs',
  templateUrl: './uptime-graphs.component.html',
  styleUrls: ['./uptime-graphs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UptimeGraphsComponent implements OnInit {
  public graphs = data;

  constructor() {}

  ngOnInit() {}
}
