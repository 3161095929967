import { MarkedOptions, MarkedRenderer } from 'ngx-markdown';

export function markedOptionsFactory(): MarkedOptions {
  const renderer = new MarkedRenderer();

  return {
    renderer,
    pedantic: false,
    tables: true,
    breaks: false,
    smartLists: true,
    smartypants: false,
    xhtml: true,
    headerIds: true,
  };
}
