import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ISidebarMenuItem } from './../../models/navigation.models';

@Injectable({
  providedIn: 'root',
})
export class MarkdownScrollService {
  private menu: ISidebarMenuItem[] = [];
  private orderedMenu: ISidebarMenuItem[] = [];
  private menuStream$ = new BehaviorSubject(this.menu);
  private orderedMenuStream$ = new BehaviorSubject(this.orderedMenu);
  private activeSubsectionStream$: BehaviorSubject<string> = new BehaviorSubject('');
  private activeSectionStream$: BehaviorSubject<string> = new BehaviorSubject('');

  constructor(@Inject(DOCUMENT) private document: Document) { }

  get orderedMenu$(): Observable<ISidebarMenuItem[]> {
    return this.orderedMenuStream$.asObservable();
  }

  get activeSubsection$(): Observable<string> {
    return this.activeSubsectionStream$.asObservable();
  }

  get activeSection$(): Observable<string> {
    return this.activeSectionStream$.asObservable();
  }

  get menu$(): Observable<ISidebarMenuItem[]> {
    return this.menuStream$.asObservable();
  }

  public resetMenu() {
    this.menu = [];
    this.menuStream$.next(this.menu);
  }

  public addMenuItem(item: ISidebarMenuItem) {
    const isFirst = !this.menu.length;
    this.menu.push({ ...item, active: isFirst, expanded: isFirst });
    this.orderedMenu = this.orderMenu(this.menu);
    this.menuStream$.next(this.menu);
    this.orderedMenuStream$.next(this.orderMenu(this.orderedMenu));
  }

  public setActiveMenuItem(id: string) {
    if (id) {
      let menuItem: ISidebarMenuItem;
      let splittedId = id.split('_');
      this.orderedMenu.forEach(item => {
        const isTargetItem = item.id === splittedId[0];
        item.active = isTargetItem;
        if (item.active) {
          item.expanded = item.active;
          this.activeSectionStream$.next(splittedId[0]);
        }
        if (isTargetItem) {
          menuItem = item;
        }
      });
      if (splittedId.length > 1) {
        this.activeSubsectionStream$.next(id);
      } else if (menuItem.subSections.length > 0) {
        this.activeSubsectionStream$.next(menuItem.subSections[0].id);
      }
    } else {
      this.orderedMenu.forEach((item, index) => {
        item.active = index === 0;
        item.expanded = item.active;
      });
    }

    this.orderedMenuStream$.next(this.orderedMenu);
  }

  public toggleExpand(id: string, value?: boolean) {
    const targetItem = this.orderedMenu.find(item => item.id === id);
    targetItem.expanded = value !== undefined ? value : !targetItem.expanded;
    this.orderedMenuStream$.next(this.orderedMenu);
  }

  public scrollTo(id: string, setActiveMenuItem = true) {
    if (setActiveMenuItem) {
      this.setActiveMenuItem(id);
    }
    const element = this.document.getElementById(id);
    if (!element) {
      return;
    }

    const offset = 40;
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = element.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;
    window.scrollTo({
      top: offsetPosition,
    });
  }

  orderMenu(menu: ISidebarMenuItem[]): ISidebarMenuItem[] {
    const processedMenuItems: ISidebarMenuItem[] = [];
    const lastSection = menu.reduce((section, menuItem) => {
      if (menuItem.level === 1) {
        if (section) {
          processedMenuItems.push(section);
        }
        return menuItem;
      }
      return { ...section, subSections: section && section.subSections && [...section.subSections, menuItem] };
    }, null);
    processedMenuItems.push(lastSection);
    return processedMenuItems;
  }
}
