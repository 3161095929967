import { MarkdownHelpersService } from './services/markdown-helpers/markdown-helpers.service';
import { MarkdownScrollService } from './services/markdown-scroll/markdown-scroll.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { filter, map, mergeMap, takeUntil } from 'rxjs/operators';
import { IMenuItem } from './models/navigation.models';
import { DrawerService } from './services/drawer/drawer.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  public menu: IMenuItem[] = [
    {
      label: 'API',
      path: '/api',
      level: 1,
      children: [],
    },    
    {
      label: 'Shopsystems',
      path: '/shopsystems',
      level: 1,
      children: [],
    },
    {
      label: 'Payments',
      path: '/payments',
      level: 1,
      children: [],
    },
    {
      label: 'Point of Sale',
      path: '/point-of-sale',
      level: 1,
      children: [],
    },
    {
      label: 'Resources',
      path: '/resources',
      level: 1,
      children: [],
    },
    {
      label: 'Support',
      path: '/support',
      level: 1,
      children: [],
    },
    {
      label: 'Account',
      path: '/account',
      level: 1,
      children: [],
    },
  ];
  public apiMenu = [...this.menu];
  destroyed$: Subject<boolean> = new Subject();

  constructor(
    private drawerService: DrawerService,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private markdownScrollService: MarkdownScrollService,
    private markdownHelpersService: MarkdownHelpersService
  ) {}

  get isDrawerOpened$(): Observable<boolean> {
    return this.drawerService.isOpened$;
  }

  ngOnInit() {
    this.setTitle();
    this.markdownScrollService.menu$.pipe(takeUntil(this.destroyed$)).subscribe(menu => {
      this.apiMenu[0].children = [
        {
          label: 'API',
          path: '/api',
          level: 2,
          parent: this.apiMenu[0].path,
        },
        ...menu.map(({ label, level, id }) => {
          const splittedId = id.split('_');
          const parent = splittedId.length > 1 ? this.markdownHelpersService.toUrlCaseString(splittedId[0]) : '/api';

          return {
            label,
            path: this.markdownHelpersService.toUrlCaseString(label),
            level: level + 2,
            active: false,
            parent,
            id,
          };
        }),
      ];
    });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private setTitle() {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data)
      )
      .subscribe(event => this.titleService.setTitle(`payever Docs${event.title ? ` | ${event.title}` : ''}`));
  }

  toggleItem(item: IMenuItem) {
    item.active = !item.active;
    if (!item.active) {
      if (item.children && item.children.length) {
        item.children.forEach(child => (child.active = false));
      }
    }
  }
}
