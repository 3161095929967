import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import { IPoint } from 'src/app/models/points.models';

@Component({
  selector: 'app-page-points',
  templateUrl: './page-points.component.html',
  styleUrls: ['./page-points.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PagePointsComponent implements OnInit {
  @Input() points: IPoint[];

  constructor() {}

  ngOnInit() {}
}
