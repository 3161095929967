import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ICardBlock } from '../../models/card-blocks.models';
import { IPoint } from '../../models/points.models';
import { CountrySelectorService } from '../../services/country-selector/country-selector.service';

@Component({
  selector: 'app-payments-container',
  templateUrl: './payments-container.component.html',
  styleUrls: ['./payments-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentsContainerComponent implements OnInit {
  heading = 'About payever Payments';
  title = 'Integrate all payment options via one API';
  description = `payever offers a wide range of payment options, from the all time classics like credit card and direct debit to installment and invoice solutions, instant payments and popular payment options like PayPal.  Integrate as many or as few payment options as you would like and don't hesitate to contact our free support at any time!`;
  points: IPoint[] = [
    {
      title: 'Simple Onboarding',
      descriptionHTML: `
      Simply enable the payment options in your free
      <a href="https://commerceos.payever.org/registration/business">payever account</a>
      and follow the prompts to connect to payment providers
      `,
    },
    {
      title: 'Easy Integrations',
      description: 'Integrate payever with your shop via one of our plugins, or create your own integration with our open REST API',
    },
    {
      title: 'Fast Support',
      descriptionHTML: `Contact our free support line <a href="mailto:support@payever.de">support@payever.de</a>
      any time for fast and competent help!`,
    },
  ];

  public links: ICardBlock[] = [
    {
      title: 'Allianz Trade pay',
      path: 'allianz-trade-pay',
      icon: 'assets/img/payments/allianz.svg',
      excludedCountries: [],
      version: 'new'
    },      
    {
      title: 'Ivy',
      path: 'ivy',
      icon: 'assets/img/payments/ivy.svg',
      excludedCountries: [],
    },    
    {
      title: 'Santander Installments',
      path: 'santander-installments',
      icon: 'assets/img/payments/santander.svg',
      excludedCountries: [],
    },
    // {
    //   title: 'Santander Splitpay',
    //   path: 'santander-factoring',
    //   icon: 'assets/img/payments/santander.svg',
    //   excludedCountries: ['se', 'dk', 'no', 'at', 'uk', 'nl', 'fi'],
    // },
    {
      title: 'Santander BNPL',
      path: 'santander-invoice',
      icon: 'assets/img/payments/santander.svg',
      excludedCountries: ['se', 'dk', 'at', 'uk', 'nl', 'fi', 'de'],
    },
    {
      title: 'Instant Payments',
      path: 'direktuberweisung',
      icon: 'assets/img/payments/santander.svg',
      excludedCountries: ['se', 'dk', 'no', 'uk', 'nl', 'fi'],
    },
    // {
    //   title: 'Santander CC Plus',
    //   path: 'santander-ccp',
    //   icon: 'assets/img/payments/santander.svg',
    //   excludedCountries: ['se', 'dk','no']
    // },
    {
      title: 'Stripe Credit Card',
      path: 'stripe-cc',
      icon: 'assets/img/payments/stripe.svg',
      excludedCountries: [],
    },
    {
      title: 'Stripe Direct Debit',
      path: 'stripe-dd',
      icon: 'assets/img/payments/stripe.svg',
      excludedCountries: ['uk'],
    },
    // {
    //   title: 'Sofort',
    //   path: 'sofort',
    //   icon: 'assets/img/payments/sofort.svg',
    //   excludedCountries: ['se', 'dk', 'no', 'uk', 'nl', 'fi'],
    // },
    {
      title: 'Paypal',
      path: 'paypal',
      icon: 'assets/img/payments/paypal.svg',
        excludedCountries: []
    },
    {
      title: 'Swedbank Pay Credit Card',
      path: 'swedbank-credit-card',
      icon: 'assets/img/payments/swedbank.svg',
      excludedCountries: ['uk', 'nl']
    },
    // {
    //   title: 'PayEx Credit Card',
    //   path: 'payex-credit-card',
    //   icon: 'assets/img/payments/payex.svg',
    //   excludedCountries: ['de']
    // },
    {
      title: 'Swedbank Pay Invoice',
      path: 'swedbank-invoice',
      icon: 'assets/img/payments/swedbank.svg',
      excludedCountries: ['de','dk', 'at', 'uk', 'nl']
    },    
    {
      title: 'Wiretransfer',
      path: 'wiretransfer',
      icon: 'assets/img/payments/wiretransfer.svg',
        excludedCountries: []
    },
    {
      title: 'Zinia BNPL',
      path: 'zinia-bnpl',
      icon: 'assets/img/payments/zinia.svg',
        excludedCountries: ['no', 'se', 'fi', 'dk', 'at', 'uk']
    },    
    {
      title: 'Zinia Installments',
      path: 'zinia-installments',
      icon: 'assets/img/payments/zinia.svg',
        excludedCountries: ['no', 'se', 'fi', 'dk', 'at', 'uk', 'nl']
    },    
    // {
    //   title: 'Wiretransfer',
    //   path: 'wiretransfer',
    //   icon: 'assets/img/payments/wiretransfer.svg',
    //     excludedCountries: []
    // },
  ];

  constructor(private countrySelectorService: CountrySelectorService) {}

  ngOnInit() {
    this.countrySelectorService.updateLanguageSelector(this.links);
  }
}
