import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ICardBlock } from '../../models/card-blocks.models';
import {IPoint} from "../../models/points.models";
import {CountrySelectorService} from "../../services/country-selector/country-selector.service";

@Component({
  selector: 'app-point-of-sale-container',
  templateUrl: './point-of-sale-container.component.html',
  styleUrls: ['./point-of-sale-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PointOfSaleContainerComponent implements OnInit {
  heading: string = 'About payever POS';
  title: string = 'Optimize your POS';
  description: string = 'Enjoy a flexible point of sale solution for any business using the latest technology, for any customer journey';

  points: IPoint[] = [
    {
      title: 'All-in-One',
      description: 'Easy maintain, monitoring and ordering of terminals.',
    },
    {
      title: 'Connect Payments',
      description: 'Support for POS payment methods such as Santander, Stripe and Cash.',
    },
    {
      title: 'QR & SMS',
      description: 'Simple integration options via the terminal API without local software dependencies.',
    },
  ];

  public links: ICardBlock[] = [
    {
      title: 'Integrations',
      path: 'integrations',
      icon: 'assets/img/point-of-sale/mobile.svg',
      excludedCountries: []
    },      
    {
      title: 'Santander Installments',
      path: 'santander-installments',
      icon: 'assets/img/point-of-sale/santander.svg',
      excludedCountries: []
    },
    {
      title: 'Zinia BNPL',
      path: 'zinia-bnpl',
      icon: 'assets/img/payments/zinia.svg',
      excludedCountries: ['no', 'se', 'fi', 'dk', 'at', 'uk']
    },    
    {
      title: 'Zinia Splitpay',
      path: 'zinia-installments',
      icon: 'assets/img/payments/zinia.svg', 
      excludedCountries: ['no', 'se', 'fi', 'dk', 'at', 'uk', 'nl']
    },    
    /*{
      title: 'Device Payments (Deprecated)',
      path: 'device-payments',
      icon: 'assets/img/point-of-sale/mobile.svg',
      excludedCountries: ['no','dk','uk']
    },*/  
  ];

  constructor(public countrySelector: CountrySelectorService) {}

  ngOnInit() {
    this.countrySelector.countryList$.next(['de', 'se', 'uk']);
  }
}
