import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ICardBlock } from '../../models/card-blocks.models';
import {IPoint} from "../../models/points.models";

@Component({
  selector: 'app-platform-container',
  templateUrl: './platform-container.component.html',
  styleUrls: ['./platform-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlatformContainerComponent implements OnInit {
  heading: string = 'test heading';
  title: string = 'test title';
  description: string = 'description';

  points: IPoint[] = [
    {
      title: 'Point one',
      description: 'payever provides an easy to integrate API for shopsystems we can´t provide a plugin for.',
    },
    {
      title: 'Point two',
      description: 'payever provides an easy to integrate API for shopsystems we can´t provide a plugin for.',
    },
    {
      title: 'Point three',
      description: 'payever provides an easy to integrate API for shopsystems we can´t provide a plugin for.',
    },
    {
      title: 'Point four',
      description: 'payever provides an easy to integrate API for shopsystems we can´t provide a plugin for.',
    },
  ];

  public links: ICardBlock[] = [
    {
      title: 'CommerceOS',
      path: '/platform/commerceos',
      icon: 'assets/img/platform/os.svg',
    },
    {
      title: 'Transactions',
      path: '/platform/transactions',
      icon: 'assets/img/platform/transactions.svg',
    },
    {
      title: 'Shop',
      path: '/platform/shop',
      icon: 'assets/img/platform/shop.svg',
    },
    {
      title: 'Mail',
      path: '/platform/mail',
      icon: 'assets/img/platform/mail.svg',
    },
    {
      title: 'Shipping',
      path: '/platform/shipping',
      icon: 'assets/img/platform/shipping.svg',
    },
    {
      title: 'Contacts',
      path: '/platform/contacts',
      icon: 'assets/img/platform/contacts.svg',
    },
    {
      title: 'Settings',
      path: '/platform/settings',
      icon: 'assets/img/platform/settings.svg',
    },
  ];

  constructor() {}

  ngOnInit() {}
}
