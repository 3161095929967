import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { IMenuItem } from 'src/app/models/navigation.models';
import { DrawerService } from './../../services/drawer/drawer.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  @Input() menu: IMenuItem[];

  constructor(protected drawerService: DrawerService) {}

  get isDrawerOpened$(): Observable<boolean> {
    return this.drawerService.isOpened$;
  }

  public onMenuItemSelected() {
    this.drawerService.toggle();
  }
}
