import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { CountryEnum } from './../../models/countries.models';
import { Location } from "@angular/common";
import {ICardBlock} from "../../models/card-blocks.models";
import {SystemStatusService} from "../system-status/system-status.service";

@Injectable({
  providedIn: 'root',
})
export class CountrySelectorService {
  countryList$: Subject<string[]> = new Subject<string[]>();
  private selectedCountry$ = new BehaviorSubject<CountryEnum>(this.country || CountryEnum.de);
  private isHiddenSelector$ = new BehaviorSubject<boolean>(false);
  public countryEnum: typeof CountryEnum = CountryEnum;

  get country$(): Observable<CountryEnum> {
    return this.selectedCountry$;
  }

  set country(country: CountryEnum) {
    localStorage.setItem('devpayever.countryCode', JSON.stringify(country));
  }

  get country(): CountryEnum {
    const country = JSON.parse(localStorage.getItem('devpayever.countryCode'));
    if (!country) {
      this.country = CountryEnum.de;
      return CountryEnum.de;
    }
    return country;
  }

  get isHidden$(): Observable<boolean> {
    return this.isHiddenSelector$;
  }

  public change(country: CountryEnum) {
    if (country) {
      this.country = country;
      this.selectedCountry$.next(country);
    }
  }

  public changeHiddenStatus(status: boolean) {
    this.isHiddenSelector$.next(status);
  }

  public updateLanguageSelector(links: ICardBlock[]) {
    const excludedCountries = Object.values(links).map(el => el.excludedCountries);
    let countries = Object.values(this.countryEnum);
    let noUpdateFlag = false;
    let resultCountries = Object.values(this.countryEnum);


    excludedCountries.forEach(val => {
      if (!noUpdateFlag) {
        if (!val.length) {
          noUpdateFlag = true;
          return false;
        }

        countries.forEach(country => {
          if (!val.find(c => c === country)) {
              resultCountries = resultCountries.filter(elem => elem !== country);
          }
        });
      }
    });

    if (!noUpdateFlag) {
        countries = countries.filter( el => resultCountries.includes(el));
        return countries;
    }

    return false;
  }
}
