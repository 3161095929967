import { CountryEnum } from './../../models/countries.models';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subscription, combineLatest, of } from 'rxjs';
import { MarkdownService } from 'ngx-markdown';
import { catchError, flatMap, map } from 'rxjs/operators';
import { CountrySelectorService } from './../../services/country-selector/country-selector.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-markdown-container',
  templateUrl: './markdown-container.component.html',
  styleUrls: ['./markdown-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MarkdownContainerComponent implements OnInit, OnDestroy {
  currentMarkdownPath$: Observable<string>;
  sidebarFolded$ = new BehaviorSubject(false);
  private subscriptions = new Subscription();
  private markdownPathParams: {
    category: string;
    pageName: string;
    language: string;
    section: string;
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private countrySelectorService: CountrySelectorService,
    private router: Router,
    private markdownService: MarkdownService,
    private location: Location,
  ) { }

  ngOnInit() {
    this.currentMarkdownPath$ = this.activatedRoute.paramMap.pipe(
      flatMap(params => {
        const category = params.get('category');
        const pageName = params.get('pageName');
        const country = params.get('language');
        const section = params.get('section');
        const defaultCountries = Object.values(CountryEnum);
        const lastCountry = country || this.countrySelectorService.country;
        this.markdownPathParams = { category, pageName, language: lastCountry, section };
        const path = `assets/markdown/${category}/${country ? `${country}/` : ''}${pageName}.md`;
        const pathLastCountry = `assets/markdown/${category}/${lastCountry}/${pageName}.md`;
        const pathDefaultCountries = defaultCountries.map(defaultCountry => `assets/markdown/${category}/${defaultCountry}/${pageName}.md`);
        const pathNoCountry = `assets/markdown/${category}/${pageName}.md`;
        const pathApiPaymentsDoc = `assets/markdown/api${this.router.url.includes('v2') ? '/v2' : (this.router.url.includes('v3') ? '/v3' : '')}`;
        const pathApiProductsDoc = `assets/markdown/api/products`;
        const pathApiPaymentLinksDoc = `assets/markdown/api/links`;
        const pathApiPaymentWidgetsDoc = `assets/markdown/api/widgets`;
        if (this.router.url.includes('api/products')) {
          return of(pathApiProductsDoc);
        }

        if (this.router.url.includes('api/links')) {
          return of(pathApiPaymentLinksDoc);
        }

        if (this.router.url.includes('api/widgets')) {
          return of(pathApiPaymentWidgetsDoc);
        }

        if (this.router.url.includes('api/payments')) {
          return of(pathApiPaymentsDoc);
        }

        if (this.location.path().includes('payments/')) {
          return of(pathLastCountry.replace('.md', ''));
        }

        return combineLatest([
          this.getSource(path),
          this.getSource(pathLastCountry),
          this.getSource(pathNoCountry),
          ...pathDefaultCountries.map(pathDefaultCountry => this.getSource(pathDefaultCountry)),
        ]).pipe(
          map(data => {
            let pathDefaultCountry = '';
            const pathExists = this.isMdFile(data[0]);
            const pathLastCountryExists = this.isMdFile(data[1]);
            const pathNoCountryExists = this.isMdFile(data[2]);
            data.splice(2, data.length - 1).find((src, i) => {
              const isMdFile = this.isMdFile(src);
              if (isMdFile) {
                pathDefaultCountry = pathDefaultCountries[i - 1];
              }
              return isMdFile;
            });

            if (!pathExists && !pathLastCountryExists && !pathDefaultCountry && pathNoCountryExists) {
              this.router.navigate([this.markdownPathParams.category, this.markdownPathParams.pageName], {
                replaceUrl: true,
              });
            } else if (!pathExists) {
              this.router.navigate([this.markdownPathParams.category, this.markdownPathParams.pageName], {
                replaceUrl: true,
              });
            }

            if (pathNoCountryExists) {
              return pathNoCountry;
            } else if (pathLastCountryExists) {
              return pathLastCountry;
            } else if (!!pathDefaultCountry) {
              return pathDefaultCountry;
            }

            return path;
          })
        );
      })
    );

    this.subscriptions.add(
      this.countrySelectorService.country$.subscribe(country => {
        if (this.markdownPathParams) {
          this.router.navigate([this.markdownPathParams.category, country, this.markdownPathParams.pageName]);
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public onToggleFoldedSidebar() {
    this.sidebarFolded$.next(!this.sidebarFolded$.getValue());
  }

  private getSource(path: string): Observable<string> {
    return this.markdownService.getSource(path).pipe(catchError(() => of('')));
  }

  private isMdFile(text: string): boolean {
    return !!text && text.indexOf('<!DOCTYPE html>') < 0;
  }
}
