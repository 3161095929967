import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ICardBlock } from 'src/app/models/card-blocks.models';
import {IPoint} from "../../models/points.models";

@Component({
  selector: 'app-quick-start-container',
  templateUrl: './quick-start-container.component.html',
  styleUrls: ['./quick-start-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuickStartContainerComponent {
  heading: string = 'test heading';
  title: string = 'test title';
  description: string = 'description';

  points: IPoint[] = [
    {
      title: 'Point one',
      description: 'payever provides an easy to integrate API for shopsystems we can´t provide a plugin for.',
    },
    {
      title: 'Point two',
      description: 'payever provides an easy to integrate API for shopsystems we can´t provide a plugin for.',
    },
    {
      title: 'Point three',
      description: 'payever provides an easy to integrate API for shopsystems we can´t provide a plugin for.',
    },
    {
      title: 'Point four',
      description: 'payever provides an easy to integrate API for shopsystems we can´t provide a plugin for.',
    },
  ];

  public links: ICardBlock[] = [
    {
      title: 'Shopify',
      path: '/shopsystems/shopify',
      icon: 'assets/img/shopsystems/shopify.svg',
    },
    {
      title: 'Presta Shop',
      path: '/shopsystems/prestashop',
      icon: 'assets/img/shopsystems/presta_shop.svg',
    },
    {
      title: 'Plentymarkets',
      path: '/shopsystems/plentymarkets',
      icon: 'assets/img/shopsystems/plenty_markets.svg',
    },
    {
      title: 'Shopware',
      path: '/shopsystems/shopware',
      icon: 'assets/img/shopsystems/shopware.svg',
    },
    {
      title: 'WooCommerce',
      path: '/shopsystems/woocommerce',
      icon: 'assets/img/shopsystems/woo_commerce.svg',
    },
    {
      title: 'xt:Commerce',
      path: '/shopsystems/xtcommerce',
      icon: 'assets/img/shopsystems/xt_commerce.svg',
    },
  ];
}
