import { Router } from '@angular/router';
import { MarkdownScrollService } from './../../services/markdown-scroll/markdown-scroll.service';
import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { IMenuItem } from 'src/app/models/navigation.models';
import { DrawerService } from '../../services/drawer/drawer.service';
import { HeaderComponent } from '../header/header.component';

@Component({
  selector: 'app-api-header',
  templateUrl: './api-header.component.html',
  styleUrls: ['./api-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApiHeaderComponent extends HeaderComponent {
  @Input() menu: IMenuItem[];
  @Output() toggleItem: EventEmitter<IMenuItem> = new EventEmitter();

  constructor(drawerService: DrawerService, private markdownScrollService: MarkdownScrollService, private router: Router) {
    super(drawerService);
  }

  get isDrawerOpened$(): Observable<boolean> {
    return this.drawerService.isOpened$;
  }

  public goToSection(path: string) {
    this.router.navigateByUrl(path);
    this.drawerService.toggle();
  }

  scrollTo(id: string) {
    this.drawerService.toggle();
    setTimeout(_ => this.markdownScrollService.scrollTo(id), 600); //  timeout due to animation
  }

  isParentActive(section: IMenuItem, child: IMenuItem): boolean {    
    const parent = section.children.find(item => item.path === child.parent);    
    return section.active && (!parent || parent.active);
  }

  onItemClick(child: IMenuItem) {
    this.toggleItem.emit(child);
    if (child.level === 4) {
      this.scrollTo(child.id);
    }
  }
}
