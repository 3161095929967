import { ChangeDetectionStrategy, Component } from '@angular/core';

import { ICardBlock } from '../../models/card-blocks.models';
import { IPoint } from "../../models/points.models";
import { CountrySelectorService } from '../../services/country-selector/country-selector.service';

@Component({
  selector: 'app-shopsystems-container',
  templateUrl: './shopsystems-container.component.html',
  styleUrls: ['./shopsystems-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShopsystemsContainerComponent {
  heading: string = 'About payever Plugins';
  title: string = 'Start using our plugins now';
  description: string = 'How to connect payever payment methods to your third party shopsystems? Start choosing a shopsystem, and visit the documentation.';

  points: IPoint[] = [
    {
      title: 'Choose Shopsystem',
      description: 'In order to integrate payever to your business, choose the shopsystem of your choice.',
    },
    {
      title: 'Download Plugin',
      description: 'The plugins are downloadable and can be directly integrated into your shopsystem.',
    },
    {
      title: 'Follow Instructions',
      description: 'Each shopsystem has an integration guide. Follow the instructions to integrate payever payments.',
    },
    {
      title: 'Test & Live',
      description: 'Make sure everything works. Insert your live credentials and receive transactions.',
    },
  ];

  public links: ICardBlock[] = [
    {
      title: 'Orocommerce',
      href: 'https://support.payever.org/hc/en-us/articles/10225059967260-OroCommerce',
      icon: 'assets/img/shopsystems/oro.svg',
      excludedCountries: [],
      version: 'new'
    },   
    {
      title: 'Smartstore',
      href: 'https://support.payever.org/hc/en-us/articles/12812230024604-Smartstore',
      icon: 'assets/img/shopsystems/smartstore.svg',
      excludedCountries: [],
      version: 'new'
    },       
    {
      title: 'Shopify',
      href: 'https://support.payever.org/hc/en-us/articles/6095452219154-Shopify',
      icon: 'assets/img/shopsystems/shopify.svg',
      excludedCountries: [],
    },
    {
      title: 'Prestashop',
      href: 'https://support.payever.org/hc/en-us/articles/360023894934-Prestashop',
      icon: 'assets/img/shopsystems/presta_shop.svg',
      excludedCountries: [],
    },
    {
      title: 'Plentymarkets',
      href: 'https://support.payever.org/hc/en-us/articles/360024069353-Plentymarkets',
      icon: 'assets/img/shopsystems/plenty_markets.svg',
      excludedCountries: [],
    },
    {
      title: 'Shopware 5',
      href: 'https://support.payever.org/hc/en-us/articles/360023894874-Shopware-5',
      icon: 'assets/img/shopsystems/shopware.svg',
      excludedCountries: [],
    },
    {
      title: 'Shopware 6',
      href: 'https://support.payever.org/hc/en-us/articles/360012435820-Shopware-6',
      icon: 'assets/img/shopsystems/shopware.svg',
      excludedCountries: [],
    },    
    {
      title: 'WooCommerce',
      href: 'https://support.payever.org/hc/en-us/articles/360024065033-WooCommerce',
      icon: 'assets/img/shopsystems/woo_commerce.svg',
      excludedCountries: [],
    },
    {
      title: 'xt:Commerce',
      href: 'https://support.payever.org/hc/en-us/articles/360024069633-xt-Commerce',
      icon: 'assets/img/shopsystems/xt_commerce.svg',
      excludedCountries: [],
    },
    {
      title: 'JTL 4',
      href: 'https://support.payever.org/hc/en-us/articles/360023894914-JTL-4',
      icon: 'assets/img/shopsystems/jtl.svg',
      excludedCountries: [],
    },
    {
      title: 'JTL 5',
      href: 'https://support.payever.org/hc/en-us/articles/360019358219-JTL-5',
      icon: 'assets/img/shopsystems/jtl.svg',
      excludedCountries: [],
    },    
    {
      title: 'OXID',
      href: 'https://support.payever.org/hc/en-us/articles/360023900334-OXID',
      icon: 'assets/img/shopsystems/oxid.svg',
      excludedCountries: [],
    },
    {
      title: 'Magento 1',
      href: 'https://support.payever.org/hc/en-us/articles/360023894894-Magento-1',
      icon: 'assets/img/shopsystems/magento.svg',
      excludedCountries: [],
    },
    {
      title: 'Magento 2',
      href: 'https://support.payever.org/hc/en-us/articles/360012044440-Magento-2',
      icon: 'assets/img/shopsystems/magento.svg',
      excludedCountries: [],
    },  
    {
      title: 'Opencart',
      href: 'https://support.payever.org/',
      icon: 'assets/img/shopsystems/opencart.svg',
      excludedCountries: [],
    },          
    {
      title: 'DanDomain',
      href: 'https://support.payever.org/hc/en-us/articles/360011957119-DanDomain-Payment-API-integration',
      icon: 'assets/img/shopsystems/dandomain.png',
      excludedCountries: ['de','no', 'se'],
    },
    {
      title: 'Commercetools',
      href: 'https://support.payever.org/hc/en-us/articles/6699064172316-Commercetools',
      icon: 'assets/img/shopsystems/commercetools.svg',
      excludedCountries: [],
    }, 
    {
      title: 'CCV Shop',
      href: 'https://support.payever.org/hc/en-us/articles/6106203706140-CCV-Shop',
      icon: 'assets/img/shopsystems/ccvshop.svg',
      excludedCountries: [],
    },       
  ];

  constructor(public countrySelector: CountrySelectorService) {}

  ngOnInit() {
    this.countrySelector.updateLanguageSelector(this.links);
  }
}
