import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { tap, map } from 'rxjs/operators';

import { ApiDocsConfig, DocsChildrenTree } from './../../models/api-docs-config.model';
import { CountrySelectorService } from '../country-selector/country-selector.service';

@Injectable({
  providedIn: 'root',
})
export class MarkdownHelpersService {
  private textAreaInstance: HTMLTextAreaElement = document.createElement('textarea');
  private sectionChanged: Subject<void> = new Subject();
  sectionChanged$: Observable<void> = this.sectionChanged.asObservable();

  constructor(private http: HttpClient, private countrySelector: CountrySelectorService) { }

  getSrcFromImg(text: string): string {
    const imgTags = text.match(/<img [^>]*src="[^"]*"[^>]*>/gm) || [];
    const imgTag = imgTags[0] || '';
    const sources = /<img src="(.*?)"/.exec(imgTag) || [];
    const src = sources[1];
    return src;
  }

  removeAllHtmlTags(text: string): string {
    return text.replace(/<(?:[^>=]|='[^']*'|="[^"]*"|=[^'"][^\s>]*)*>/g, '');
  }

  encodeHtmlEntities(text: string): string {
    return text
      .replace(/[\u00A0-\u9999<>\&]/gim, function (i) {
        return '&#' + i.charCodeAt(0) + ';';
      })
      .trim();
  }

  decodeHtmlEntities(text: string): string {
    this.textAreaInstance.innerHTML = text;

    return this.textAreaInstance.value;
  }

  toPascalCase(path: string): string {
    const fromUrlCase = path.includes('-');
    const splitter = fromUrlCase ? '-' : ' ';
    return path
      .replace(/\//g, '-')
      .replace(/\-(v\d)/, (x, y) => `(${y})`)
      .toLowerCase()
      .split(splitter)
      .map(str => str[0].toUpperCase() + str.substr(1, str.length - 1))
      .join('');
  }

  toUrlCaseString(path: string): string {
    if (!path) {
      return '';
    }
    return path
      .replace(/\/|\(|\)/g, '-')
      .replace(/\.?([A-Z])/g, (x, y) => '-' + y.toLowerCase())
      .replace(/^-|-$| /g, '');
  }

  emitSectionChanged() {
    this.sectionChanged.next();
  }

  getPaymentsApiDocsConfig(): Observable<string[]> {
    return this.http
      .get<ApiDocsConfig>('../../../assets/markdown/api/payments/config.json')
      .pipe(map(config => this.getAllPaths(config.children)));
  }

  getPaymentsLatestApiDocsConfig(): Observable<string[]> {
    return this.http
      .get<ApiDocsConfig>('../../../assets/markdown/api/payments/v3/config.json')
      .pipe(map(config => this.getAllPaths(config.children)));
  }

  getPaymentsNewApiDocsConfig(): Observable<string[]> {
    return this.http
      .get<ApiDocsConfig>('../../../assets/markdown/api/payments/v2/config.json')
      .pipe(map(config => this.getAllPaths(config.children)));
  }

  getProductsApiDocsConfig(): Observable<string[]> {
    return this.http
      .get<ApiDocsConfig>('../../../assets/markdown/api/products/config.json')
      .pipe(map(config => this.getAllPaths(config.children)));
  }

  getPaymentLinksApiDocsConfig(): Observable<string[]> {
    return this.http
      .get<ApiDocsConfig>('../../../assets/markdown/api/links/config.json')
      .pipe(map(config => this.getAllPaths(config.children)));
  }  

  getPaymentWidgetsApiDocsConfig(): Observable<string[]> {
    return this.http
      .get<ApiDocsConfig>('../../../assets/markdown/api/widgets/config.json')
      .pipe(map(config => this.getAllPaths(config.children)));
  }  

  getPaymentDocsConfig(): Observable<string[]> {
    return this.http
      .get<ApiDocsConfig>('../../../assets/markdown/payments/config.json')
      .pipe(map(config => this.getAllPaths(config.children)));
  }

  getAllPaths(trees: DocsChildrenTree[]): string[] {
    const items: string[] = [];
    trees.forEach(tree => {
      if (!tree.children) {
        items.push(tree.path);
      } else {
        items.push(...this.getAllPaths(tree.children));
      }
    });
    return items;
  }

  isMdFile(text: string): boolean {
    return !!text && text.indexOf('<!DOCTYPE html>') < 0 && text.indexOf('<meta') < 0;
  }
}
