import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Injector, NgModule } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { FormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularDropdownModule } from 'angular-dropdown';
import { MarkdownModule, MarkedOptions } from 'ngx-markdown';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CountrySelectorComponent } from './components/country-selector/country-selector.component';
import { FooterComponent } from './components/footer/footer.component';
import { HamburgerComponent } from './components/hamburger/hamburger.component';
import { HeaderComponent } from './components/header/header.component';
import { LandingBlockComponent } from './components/landing-block/landing-block.component';
import { LandingPageTitleAreaComponent } from './components/landing-page-title-area/landing-page-title-area.component';
import { LogotypeComponent } from './components/logotype/logotype.component';
import { markedOptionsFactory } from './components/markdown/markdown-options';
import { MarkdownComponent } from './components/markdown/markdown.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { PageCardBlockComponent } from './components/page-card-block/page-card-block.component';
import { PageCardBlocksComponent } from './components/page-card-blocks/page-card-blocks.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { PageInformationComponent } from './components/page-information/page-information.component';
import { PagePointsComponent } from './components/page-points/page-points.component';
import { QuestionsBarComponent } from './components/questions-bar/questions-bar.component';
import { QuestionsBlockComponent } from './components/questions-block/questions-block.component';
import { SearchComponent } from './components/search/search.component';
import { SidebarNavigationComponent } from './components/sidebar-navigation/sidebar-navigation.component';
import { SliderComponent } from './components/slider/slider.component';
import { TopBarComponent } from './components/top-bar/top-bar.component';
import { UptimeGraphsComponent } from './components/uptime-graphs/uptime-graphs.component';
import { AccountContainerComponent } from './containers/account-container/account-container.component';
import { LandingContainerComponent } from './containers/landing-container/landing-container.component';
import { MarkdownContainerComponent } from './containers/markdown-container/markdown-container.component';
import { PaymentsContainerComponent } from './containers/payments-container/payments-container.component';
import { PlatformContainerComponent } from './containers/platform-container/platform-container.component';
import { PointOfSaleContainerComponent } from './containers/point-of-sale-container/point-of-sale-container.component';
import { QuickStartContainerComponent } from './containers/quick-start-container/quick-start-container.component';
import { ResourcesContainerComponent } from './containers/resources-container/resources-container.component';
import { ShopsystemsContainerComponent } from './containers/shopsystems-container/shopsystems-container.component';
import { ApiContainerComponent } from './containers/api-container/api-container.component';
import { SupportContainerComponent } from './containers/support-container/support-container.component';
import { SystemStatusContainerComponent } from './containers/system-status-container/system-status-container.component';
import { ScrollSpyDirective } from './directives/scroll-spy.directive';
import { SystemStatusTotalComponent } from './components/system-status-total/system-status-total.component';
import { ApiHeaderComponent } from './components/api-header/api-header.component';

@NgModule({
  declarations: [
    AppComponent,
    TopBarComponent,
    QuestionsBarComponent,
    FooterComponent,
    HeaderComponent,
    CountrySelectorComponent,
    SidebarNavigationComponent,
    LogotypeComponent,
    NavigationComponent,
    PageHeaderComponent,
    QuickStartContainerComponent,
    ShopsystemsContainerComponent,
    ApiContainerComponent,    
    PaymentsContainerComponent,
    PointOfSaleContainerComponent,
    PlatformContainerComponent,
    ResourcesContainerComponent,
    SupportContainerComponent,
    AccountContainerComponent,
    PageInformationComponent,
    PagePointsComponent,
    PageCardBlockComponent,
    PageCardBlocksComponent,
    MarkdownContainerComponent,
    MarkdownComponent,
    ScrollSpyDirective,
    LandingContainerComponent,
    LandingBlockComponent,
    SearchComponent,
    LandingPageTitleAreaComponent,
    HamburgerComponent,
    QuestionsBlockComponent,
    SliderComponent,
    SystemStatusContainerComponent,
    UptimeGraphsComponent,
    SystemStatusTotalComponent,
    ApiHeaderComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,    
    HttpClientModule,
    MarkdownModule.forRoot({
      loader: HttpClient,
      markedOptions: {
        provide: MarkedOptions,
        useFactory: markedOptionsFactory,
      },
    }),
    AngularDropdownModule,
    MatExpansionModule,
    MatTooltipModule,
    SwiperModule,
  ],
  entryComponents: [SliderComponent],
  providers: [],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {
  constructor(private injector: Injector) {
    const slider = createCustomElement(SliderComponent, {
      injector: this.injector,
    });
    customElements.define('slider-element', slider);
  }
}
