import {AfterViewInit, Component, ElementRef, Input, ViewChild, ViewEncapsulation} from '@angular/core';
import { SwiperConfigInterface, SwiperDirective } from 'ngx-swiper-wrapper';
import { ISlide } from 'src/app/models/slider.models';
import {BreakpointObserver} from "@angular/cdk/layout";

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SliderComponent implements AfterViewInit {
  @Input() slides: ISlide[];
  @Input() isTables: boolean;
  @Input() paginationClass: string;
  @ViewChild(SwiperDirective, {static: false}) directiveRef: SwiperDirective;
  @ViewChild('table', {static: false}) table: ElementRef;
  active: number = 0;
  private isMobile: boolean = this.breakpointObserver.isMatched('(max-width: 520px)');

  private tableMaxHeight: number = 0;

  public config: SwiperConfigInterface = {
    direction: 'horizontal',
    spaceBetween: 50,
    navigation: {
      nextEl: '.slider__right-arrow',
      prevEl: '.slider__left-arrow',
    },
    effect: 'fade',
    loop: true,
    pagination: {
      el: '.slider__pagination',
      clickable: true,
      hideOnClick: false,
    },
  };

  public tablesConfig: SwiperConfigInterface = {
    direction: 'horizontal',
    spaceBetween: 50,
    navigation: {
      nextEl: '.slider__right-arrow',
      prevEl: '.slider__left-arrow',
    },
    allowTouchMove: this.isMobile,
    effect: 'fade',
    loop: true,
    pagination: {
      el: '.slider__pagination',
      clickable: true,
      hideOnClick: false,
    },
  };

  constructor(private breakpointObserver: BreakpointObserver) {}

  getHeight(el: HTMLTableElement) {
    this.tableMaxHeight = el.getBoundingClientRect().height > this.tableMaxHeight ?
      el.getBoundingClientRect().height :
      this.tableMaxHeight;
  }

  ngAfterViewInit() {
    setTimeout(()=> {
      if(this.isTables) {
        this.getHeight(this.table.nativeElement);
      }
    }, 1500); //refactor to track moment when input passed via accessor or service
  }

  prevSlide() {
    this.directiveRef.prevSlide();
    if (this.active === 0) {
      this.active = this.slides.length -1;
    } else {
      this.active--;
    }
  }

  nextSlide() {
    this.directiveRef.nextSlide();
    if (this.slides.length-1 <= this.active){
      this.active = 0;
    } else {
      this.active++;
    }
  }

  onSlideChange(index: number) {
    if (this.isMobile) {
      this.active = index;
    }
  }

  getTableHTML(slide: any) {
    const thead: HTMLElement = document.createElement('thead');
    const tbody: HTMLElement = document.createElement('tbody');
    for (let i=0; i < slide.thead.length; i++) {
      thead.append(slide.thead[i]);
    }
    for (let i=0; i < slide.tbody.length; i++) {
      tbody.append(slide.tbody[i]);
    }
    return `<table>${thead.innerHTML}${tbody.innerHTML}</table>`;
  }
}
