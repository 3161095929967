import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  ViewEncapsulation,
  OnDestroy,
  Inject,
  Renderer2,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BreakpointObserver } from '@angular/cdk/layout';
import * as ClipboardJS from 'clipboard/dist/clipboard';
import { MarkdownService } from 'ngx-markdown';
import { ISlide } from 'src/app/models/slider.models';
import { MarkdownHelpersService } from 'src/app/services/markdown-helpers/markdown-helpers.service';
import { CodeHighlightService } from './../../services/code-highlight/code-highlight.service';
import { MarkdownScrollService } from './../../services/markdown-scroll/markdown-scroll.service';
import { CountrySelectorService } from '../../services/country-selector/country-selector.service';
import { CountryEnum } from '../../models/countries.models';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Subject, Observable, forkJoin, from, iif } from 'rxjs';
import { takeUntil, filter, map, switchMap, tap, first } from 'rxjs/operators';
import { DOCUMENT, Location } from '@angular/common';

const isAbsolute = new RegExp('(?:^[a-z][a-z0-9+.-]*:|//)', 'i');

@Component({
  selector: 'app-markdown',
  templateUrl: './markdown.component.html',
  styleUrls: ['./markdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MarkdownComponent implements OnInit, AfterViewChecked, OnChanges, OnDestroy {
  defaultApiSection = 'Authentication';

  listener: () => void;

  @Input()
  get path(): string {
    return this._path;
  }
  set path(path: string) {
    if (!path) {
      return;
    }

    this._path = path;
    if (path.includes('api/products')) {
      const splittedPath = path.split('/');
      const mainPath = path
        .split('/')
        .slice(0, splittedPath.length - 2)
        .join('/');

      this.sources$ = this.markdownHelpersService.getProductsApiDocsConfig().pipe(
        switchMap(sectionPaths => {
          return forkJoin(
            sectionPaths.map(sectionPath => {
              const path = `${mainPath}/api/${sectionPath.split('api/')[1]}`;
              return this.markdownService.getSource(path);
            })
          ).pipe(map(sources => sources.filter(src => this.markdownHelpersService.isMdFile(src)).join('\n')));
        })
      );
    } else if (path.includes('api/links')) {
      const splittedPath = path.split('/');
      const mainPath = path
        .split('/')
        .slice(0, splittedPath.length - 2)
        .join('/');

      this.sources$ = this.markdownHelpersService.getPaymentLinksApiDocsConfig().pipe(
        switchMap(sectionPaths => {
          return forkJoin(
            sectionPaths.map(sectionPath => {
              const path = `${mainPath}/api/${sectionPath.split('api/')[1]}`;
              return this.markdownService.getSource(path);
            })
          ).pipe(map(sources => sources.filter(src => this.markdownHelpersService.isMdFile(src)).join('\n')));
        })
      );
    } else if (path.includes('api/widgets')) {
      const splittedPath = path.split('/');
      const mainPath = path
        .split('/')
        .slice(0, splittedPath.length - 2)
        .join('/');

      this.sources$ = this.markdownHelpersService.getPaymentWidgetsApiDocsConfig().pipe(
        switchMap(sectionPaths => {
          return forkJoin(
            sectionPaths.map(sectionPath => {
              const path = `${mainPath}/api/${sectionPath.split('api/')[1]}`;
              return this.markdownService.getSource(path);
            })
          ).pipe(map(sources => sources.filter(src => this.markdownHelpersService.isMdFile(src)).join('\n')));
        })
      );      
    } else if (path.includes('api')) {
      const splittedPath = path.split('/');
      const version = splittedPath[3];
      const mainPath = path
        .split('/')
        .slice(0, splittedPath.length - (version ? 2 : 1))
        .join('/');
    
      let configObservable: Observable<string[]>;
          
      if (version === undefined || !['v2', 'v3'].includes(version)) {
        configObservable = this.markdownHelpersService.getPaymentsApiDocsConfig();
      } else if (version === 'v2') {
        configObservable = this.markdownHelpersService.getPaymentsNewApiDocsConfig();
      } else if (version === 'v3' ) {
        configObservable = this.markdownHelpersService.getPaymentsLatestApiDocsConfig();
      }
    
      this.sources$ = configObservable.pipe(
        switchMap(sectionPaths => {
          return forkJoin(
            sectionPaths.map(sectionPath => {
              const path = `${mainPath}/api/${sectionPath.split('api/')[1]}`;
              return this.markdownService.getSource(path);
            })
          ).pipe(map(sources => sources.filter(src => this.markdownHelpersService.isMdFile(src)).join('\n')));
        })
      );
    } else if (path.includes('payments')) {
      const mainPath = path.split('.md')[0];

      this.sources$ = this.markdownHelpersService.getPaymentDocsConfig().pipe(
        tap(sectionPaths => {
          const relevantCountries = this.defaultCountries.filter(country =>
            sectionPaths.some(path => path.includes(`${country}/${this.routeParams.pageName}`))
          );
          this.countrySelector.countryList$.next(relevantCountries);
        }),
        map(sectionPaths => sectionPaths.filter(sectionPath => sectionPath.includes(mainPath))),
        switchMap(sectionPaths => {
          return forkJoin(
            sectionPaths.map(sectionPath => {
              const path = sectionPath.replace('src/', '');
              return this.markdownService.getSource(path);
            })
          ).pipe(map(sources => sources.filter(src => this.markdownHelpersService.isMdFile(src)).join('\n')));
        })
        // tap(() => !this.listener && this.attachRouterHandler())
      );
    }
  }

  @ViewChild('markdown', { static: false }) markdown: ElementRef;

  sources$: Observable<string>;
  tree = {
    level1: '',
    level2: '',
    level3: '',
    level4: '',
    level5: '',
  };

  _path: string;
  paths: string[] = [];

  private readonly defaultCountries: string[] = Object.keys(CountryEnum);
  private firstHeaderWasRendered = false;
  private isViewInitialized = false;
  private isMobile: boolean = this.breakpointObserver.isMatched('(max-width: 520px)');
  private sectionId = '';
  private routeParams: Params = {};

  private destroyed$: Subject<boolean> = new Subject();

  constructor(
    private breakpointObserver: BreakpointObserver,
    private markdownService: MarkdownService,
    private titleService: Title,
    private codeHighlightService: CodeHighlightService,
    private markdownScrollService: MarkdownScrollService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private renderer: Renderer2,
    private element: ElementRef,
    public countrySelector: CountrySelectorService,
    public markdownHelpersService: MarkdownHelpersService,
    @Inject(DOCUMENT) private document
  ) {
    router.events.subscribe(event => {
      if (event.constructor.name === 'NavigationStart') {
        this.countrySelector.countryList$.next(this.defaultCountries);
      }
    });

    route.params
      .pipe(
        takeUntil(this.destroyed$),
        tap(params => (this.routeParams = params)),
        filter(({ section }) => !!section),
        tap(({ section, subSection, h3, h4, h5 }) => {
          const sectionId = this.markdownHelpersService.toPascalCase(section);
          const h3Id = h3 ? '_' + this.markdownHelpersService.toPascalCase(h3) : '';
          const h4Id = h4 ? '_' + this.markdownHelpersService.toPascalCase(h4) : '';
          const h5Id = h5 ? '_' + this.markdownHelpersService.toPascalCase(h5) : '';
          this.sectionId = subSection
            ? `${sectionId}_${this.markdownHelpersService.toPascalCase(subSection)}${h3Id}${h4Id}${h5Id}`
            : sectionId;
        })
      )
      .subscribe();
  }

  ngOnInit() {
    this.initClipboardButtons();
    this.prepareMarkdown();
  }

  ngOnChanges() {
    this.firstHeaderWasRendered = false;
    this.markdownScrollService.resetMenu();
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this.listener();
    this.listener = null;
  }

  ngAfterViewChecked() {
    this.codeHighlightService.highlightAll();
    if (!this.listener) {
      this.attachRouterHandler();
    }
    if (!this.isViewInitialized && this.document.getElementById(this.sectionId)) {
      this.isViewInitialized = true;
      this.allImagesLoaded()
        .pipe(
          first(),
          tap(() => this.markdownScrollService.scrollTo(this.sectionId))
        )
        .subscribe();
    }
  }

  onMarkdownLoad() {
    if (this.listener) {
      this.listener();
    }

    this.attachRouterHandler();
  }

  public onScrollSectionChange(id: string) {
    if (!id) {
      return;
    }

    const sectionId = id.split('_')[0];
    const subSectionId = id.split('_')[1];

    if (this.route.snapshot.data.accessSectionsByUrl) {
      this.updateUrl(sectionId, subSectionId);
    }

    this.markdownScrollService.setActiveMenuItem(id);
    this.markdownScrollService.toggleExpand(sectionId, true);
    this.markdownHelpersService.emitSectionChanged();
  }

  private prepareMarkdown() {
    this.markdownService.renderer.hr = () => {
      return '';
    };

    this.markdownService.renderer.paragraph = text => {
      return text.indexOf('id: ') !== -1 ? '' : `<p>${text}</p>`;
    };

    this.markdownService.renderer.table = (header: string, body: string) => {
      const stubTable: HTMLTableElement = this.document.createElement('table') as HTMLTableElement;
      stubTable.createTHead();
      stubTable.createTBody();
      stubTable.tHead.innerHTML = header;
      stubTable.tBodies[0].innerHTML = body;
      stubTable.tBodies[0].getElementsByTagName('td');

      //wrap textNode in div for possible set gradient border
      for (let thIndex = 0; thIndex < stubTable.tHead.getElementsByTagName('th').length; thIndex++) {
        stubTable.tHead.getElementsByTagName('th')[thIndex].innerHTML = `<div class="cell-inner-block">${
          stubTable.tHead.getElementsByTagName('th')[thIndex]['innerText']
        }</div>`;
      }
      for (let tdIndex = 0; tdIndex < stubTable.tBodies[0].getElementsByTagName('td').length; tdIndex++) {
        stubTable.tBodies[0].getElementsByTagName('td')[tdIndex].innerHTML = `<div class="cell-inner-block">${
          stubTable.tBodies[0].getElementsByTagName('td')[tdIndex]['innerHTML']
        }</div>`;
      }

      // divide table to small tables with 4 columns and wrap each table in swiper slide
      const columnsPerSlideMobile: number = 2;
      const columnsPerSlideDesktop: number = 4;
      const minAmountOfTotalColumns: number = 4; // when more, then tables will be divided inside swiper

      const lengthOfTable: number = stubTable.getElementsByTagName('th').length;
      const tbodyRows = stubTable.tBodies[0].getElementsByTagName('tr');
      if (lengthOfTable > minAmountOfTotalColumns) {
        const columnsPerSlide = this.isMobile ? columnsPerSlideMobile : columnsPerSlideDesktop;
        const slidesQuantity: number = Math.ceil(lengthOfTable / columnsPerSlide);
        const slides: any[] = [];
        for (let i = 0; i < slidesQuantity; i++) {
          slides[i] = { thead: [], tbody: [] };
          for (let j = 0; j < columnsPerSlide; j++) {
            if (stubTable.tHead.getElementsByTagName('th')[i * columnsPerSlide + j]) {
              slides[i].thead.push(stubTable.tHead.getElementsByTagName('th')[i * columnsPerSlide + j]);
            }
          }

          for (let q = 0; q < tbodyRows.length; q++) {
            const tr = this.document.createElement('tr');
            for (let k = 0; k < columnsPerSlide; k++) {
              if (stubTable.tBodies[0].getElementsByTagName('tr')[q].children.length !== 0) {
                tr.append(stubTable.tBodies[0].getElementsByTagName('tr')[q].children[0]);
              }
            }
            slides[i].tbody.push(tr);
          }
        }
        const randomId = Math.random().toString(36).substring(5);

        setTimeout(_ => {
          (this.document.getElementById(randomId) as any).paginationClass = randomId;
          (this.document.getElementById(randomId) as any).slides = slides;
          (this.document.getElementById(randomId) as any).isTables = true;
        }, 1000);

        return `<div class="container"><slider-element id="${randomId}"></slider-element></div>`;
      }

      return `<table>${header}${body}</table>`;
    };

    this.markdownService.renderer.heading = (text: string, level: number) => {
      if (level === 6) {
        const src = this.markdownHelpersService.getSrcFromImg(text);
        const title = this.markdownHelpersService.removeAllHtmlTags(text);

        return `
          <div class="markdown-header">
            <div class="markdown-header__container">
              <img class="markdown-header__icon" src="${src}" />
              <div class="markdown-header__text">${title}</div>
            </div>
          </div>
        `;
      }

      if (text.indexOf('title: ') !== -1) {
        const title = text.replace('title: ', '');
        const currentTitle = this.titleService.getTitle();
        this.titleService.setTitle(`${currentTitle} - ${title}`);
        return '';
      }
      const id = `${this.markdownHelpersService.toPascalCase(text.replace(/\//g, '&or&'))}`;
      this.tree['level' + level] = id;

      let subSectionId = ''; // id format - {level1}_${level2}?_{level3}?_{level4}

      for (let i = 1; i <= level; i++) {
        const id = this.tree['level' + i];
        subSectionId += i === 1 ? id : '_' + id;
      }

      if (level <= 2) {
        this.markdownScrollService.addMenuItem({
          label: text,
          id: level === 1 ? id : subSectionId,
          level,
          subSections: [],
        });
      }

      const header = `<h${level} ${level !== 1 ? 'id=' + subSectionId : ''}>${text}</h${level}>`;
      const withLineAndSection = `<section id="${id}">${this.firstHeaderWasRendered ? '<div class="line"></div>' : ''}${header}</section>`;
      if (!this.firstHeaderWasRendered) {
        this.firstHeaderWasRendered = true;
      }

      return level === 1 ? withLineAndSection : header;
    };

    this.markdownService.renderer.code = (code, language) => {
      return `
        <div class="container code-container">
          <div class="code-container__tabs">
            <div class="code-container__tab">${language}</div>
          </div>
          <button class="copy code-container__copy">Copy</button>
          <div class="code-container__content">
            <pre><code class="language-${language}">${this.markdownHelpersService.encodeHtmlEntities(code)}</code></pre>
          </div>
        </div>
      `;
    };

    this.markdownService.renderer.list = body => {
      const images = /<img src="(.*?)"/.exec(body);
      const isSlider = images && images.length;

      if (isSlider) {
        const slides: ISlide[] = body.match(/<li>([\s\S]*?)<\/li>/gm).map(s => {
          const textTags = s.match(/<li>(.*?)\n/g) || [];
          const textTag = textTags[0] || '';
          const text = this.markdownHelpersService.decodeHtmlEntities(this.markdownHelpersService.removeAllHtmlTags(textTag));

          const src = this.markdownHelpersService.getSrcFromImg(s);

          return {
            text,
            src,
          };
        });

        const randomId = Math.random().toString(36).substring(5);

        setTimeout(_ => {
          (this.document.getElementById(randomId) as any).slides = slides;
        }, 1000);

        return `<div class="container"><slider-element id="${randomId}"></slider-element></div>`;
      } else {
        return body;
      }
    };
  }

  private initClipboardButtons() {
    const clipboard = new ClipboardJS('.copy', {
      target: trigger => {
        return trigger.nextElementSibling;
      },
    });

    clipboard.on('success', event => {
      event.trigger.textContent = 'Copied!';
      setTimeout(() => {
        event.clearSelection();
        event.trigger.textContent = 'Copy';
      }, 500);
    });
  }

  allImagesLoaded(): Observable<any> {
    const images: HTMLImageElement[] = Array.from(this.document.getElementsByTagName('img'));
    const promises = images.map((image, i) => {
      return new Promise((resolve, reject) => {
        image.onload = resolve;
        image.onerror = reject;
      });
    });

    return from(Promise.all(promises));
  }

  updateUrl(section: string, subSection: string) {
    const newSection = this.markdownHelpersService.toUrlCaseString(section) + '/' + this.markdownHelpersService.toUrlCaseString(subSection);
    let path = '';
    let version: string = null;
    let apiPath = '';
    const isApiDocs = this.router.url.includes('/api');
    const isApiPaymentDocs = this.router.url.includes('api/payments');
    const isApiProductDocs = this.router.url.includes('api/products');
    const isApiLinksDocs = this.router.url.includes('api/links');
    const isApiWidgetsDocs = this.router.url.includes('api/widgets');

    if (isApiDocs) {
      const splittedPath = this.router.url.split('/api');
      path = splittedPath[0];
      apiPath = '/api/';
      if (isApiPaymentDocs) {
        const matches = splittedPath[1].match(/(v\d)/);
        version = matches && matches.length > 0 ? matches[0] : '';
      }
    } else {
      path = `${this.routeParams.category}${this.routeParams.language ? `/${this.routeParams.language}` : '' }/${this.routeParams.pageName}/`;
    }

    if (isApiPaymentDocs) {
      apiPath = `/api/payments/${version ? `${version}/` : ''}`;
    } else if (isApiProductDocs) {
      apiPath = '/api/products/';
    } else if (isApiLinksDocs) {
      apiPath = '/api/links/';
    } else if (isApiWidgetsDocs) {
      apiPath = '/api/widgets/';
    }

    const newUrl = `${path}${apiPath}${newSection}`;
    this.location.replaceState(newUrl);
  }

  attachRouterHandler() {
    this.listener = this.renderer.listen(this.element.nativeElement, 'click', (e: Event) => {
      if (e.target && (e.target as any).tagName === 'A') {
        const el = e.target as HTMLElement;
        const linkURL = el.getAttribute && el.getAttribute('href');
        if (linkURL && !isAbsolute.test(linkURL)) {
          e.preventDefault();
          const params: string[] = linkURL
            .replace('api/payments', '')
            .split('/')
            .filter(param => !!param && ![this.routeParams.category, this.routeParams.language, this.routeParams.pageName].includes(param));
          const [section, subSection, h3, h4, h5] = params;
          const sectionId = this.markdownHelpersService.toPascalCase(section);
          const h3Id = h3 ? '_' + this.markdownHelpersService.toPascalCase(h3) : '';
          const h4Id = h4 ? '_' + this.markdownHelpersService.toPascalCase(h4) : '';
          const h5Id = h5 ? '_' + this.markdownHelpersService.toPascalCase(h5) : '';
          this.sectionId = subSection
            ? `${sectionId}_${this.markdownHelpersService.toPascalCase(subSection)}${h3Id}${h4Id}${h5Id}`
            : sectionId;
          if (this.document.getElementById(this.sectionId)) {
            this.markdownScrollService.scrollTo(this.sectionId);
          } else {
            this.router.navigate([linkURL]);
          }
        }
      }
    });
  }
}
