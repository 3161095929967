import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { IFooterRow } from 'src/app/models/footer.models';
import { footerData } from './footer.data';
import { CountrySelectorService } from '../../services/country-selector/country-selector.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  public rows: IFooterRow[] = footerData;

  constructor(private countrySelector: CountrySelectorService, private changeDetector: ChangeDetectorRef) {}

  ngOnInit() {
    // this.countrySelector.country$.subscribe(() => {
    //   this.changeDetector.detectChanges();
    // })
  }

  availableCountry(row: any): boolean {
    if (row.excludedCountries) {
      return row.excludedCountries.indexOf(this.countrySelector.country) === -1;
    } else {
      return true;
    }
  }
}
