import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ICardBlock } from '../../models/card-blocks.models';
import {IPoint} from "../../models/points.models";

@Component({
  selector: 'app-resources-container',
  templateUrl: './resources-container.component.html',
  styleUrls: ['./resources-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourcesContainerComponent implements OnInit {
  heading: string = 'About payever Resources';
  title: string = 'Find useful resources';
  description: string = 'Everything you need to setup the payever Payments and Integrations in a sandbox or production environment.';

  points: IPoint[] = [
    {
      title: 'References',
      description: 'Discover the APIs of specific resources and payments.',
    },
    {
      title: 'Test Triggers',
      description: 'payever provides a stub data list for all payments for smooth testing.',
    },
    {
      title: 'Pre-production',
      description: 'Properly test your payment integrations before going to production.',
    },
  ];

  public links: ICardBlock[] = [
    {
      title: 'Test Credentials',
      path: '/resources/de/test-credentials',
      icon: 'assets/img/resources/key.svg',
    },
    {
      title: 'API References',
      path: '/resources/references',
      icon: 'assets/img/resources/api.svg',
    }
  ];

  constructor() {}

  ngOnInit() {}
}
