import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IMenuItem } from './../../models/navigation.models';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationComponent {
  @Input() menu: IMenuItem[];
  @Input() opened: boolean;
}
