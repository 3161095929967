import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ICardBlock } from '../../models/card-blocks.models';
import {IPoint} from "../../models/points.models";

@Component({
  selector: 'app-support-container',
  templateUrl: './support-container.component.html',
  styleUrls: ['./support-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupportContainerComponent implements OnInit {
  heading: string = 'About payever Support';
  title: string = 'Solve your problems';
  description: string = 'We are here 24/7 to help you with your problems. Contact us via mail or chat or find an answer to your questions inside our documentation.';

  points: IPoint[] = [
    {
      title: 'Around The Clock',
      description: 'It doesn’t matter the time of day – we are available to help you find a solution.',
    },
    {
      title: 'Chat & Mail',
      description: 'We are available via live chat or by email.',
    },
    {
      title: 'Multilingual',
      description: 'payever provides support in English, German and Spanish.',
    },
  ];

  public links: ICardBlock[] = [
    {
      title: 'Bug Reporting',
      href: 'mailto:support@payever.org?subject=Report a bug',
      icon: 'assets/img/support/bug.svg',
      hrefText: 'Report Bug',
    },
/*    {
      title: 'System status',
      path: '/support/system-status',
      icon: 'assets/img/resources/api.svg',
    },*/
    {
      title: 'Contact Us',
      href: 'https://support.payever.org/hc/en-us/requests/new',
      icon: 'assets/img/support/contact.svg',
      hrefText: 'Help Center',
    },
  ];

  constructor() {}

  ngOnInit() {}
}
