import { MarkdownHelpersService } from 'src/app/services/markdown-helpers/markdown-helpers.service';
import { Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { Observable, Subject, merge } from 'rxjs';
import { distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';
import { IBreadCrumb } from 'src/app/models/breadcrumbs.models';
import { CountryEnum } from 'src/app/models/countries.models';
import { CountrySelectorService } from './../../services/country-selector/country-selector.service';
import { CompileShallowModuleMetadata } from '@angular/compiler';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopBarComponent implements OnInit, OnDestroy {
  breadcrumbs$: Observable<IBreadCrumb[]>;
  destroyed$: Subject<boolean> = new Subject();

  constructor(
    public location: Location,
    private router: Router,
    private countrySelectorService: CountrySelectorService,
    private markdownHelpersService: MarkdownHelpersService,
    private cdr: ChangeDetectorRef,
    public route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.breadcrumbs$ = merge(
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        distinctUntilChanged()
      ),
      this.markdownHelpersService.sectionChanged$.pipe(takeUntil(this.destroyed$))
    ).pipe(map(_ => this.getBreadCrumbs()));
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private getBreadCrumbs() {
    const breadcrumbs = [{ label: 'Documentation', path: '/' }];
    let path = '';
    let pathArray = this.location
      .path()
      .replace(/-/gi, ' ')
      .split('/')
      .filter(n => n)
      .map(n => n.replace(/&or&/g, '/'));

    const isPaymentsPage = !!pathArray.filter(p => p === 'payments').length;
    const isApiPage = !!pathArray.filter(p => p === 'api').length;  
    const isShopsystemsPage = !!pathArray.filter(p => p === 'shopsystems').length;
    const isPosPage = !!pathArray.filter(p => p === 'point of sale').length;
    const locale = pathArray.find(
      item => item.toLowerCase() === 'de' || item.toLowerCase() === 'no' || item.toLowerCase() === 'dk' || item.toLowerCase() === 'se'
    );

    pathArray = pathArray.filter(
      item => item.toLowerCase() !== 'de' && item.toLowerCase() !== 'no' && item.toLowerCase() !== 'dk' && item.toLowerCase() !== 'se'
    );

    if (locale) {
      this.countrySelectorService.changeHiddenStatus(false);
      this.countrySelectorService.change(locale.toLowerCase() as CountryEnum);
    }

    if (isPaymentsPage || isShopsystemsPage || isPosPage || isApiPage) {
      this.countrySelectorService.changeHiddenStatus(false);
    } else {
      this.countrySelectorService.changeHiddenStatus(true);
    }

    pathArray.forEach(breadcrumb => {
      path = path + '/' + breadcrumb.replace(/\s+/g, '-');
      breadcrumbs.push({ label: breadcrumb, path });
    });
    return breadcrumbs;
  }
}
