import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { DrawerService } from './../../services/drawer/drawer.service';

@Component({
  selector: 'app-hamburger',
  templateUrl: './hamburger.component.html',
  styleUrls: ['./hamburger.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HamburgerComponent {
  constructor(private drawerService: DrawerService) {}

  get isOpened$(): Observable<boolean> {
    return this.drawerService.isOpened$;
  }

  public onPressed() {
    this.drawerService.toggle();
  }
}
