import { ISystemStatusCategory } from 'src/app/models/system-status.models';

export const catalogSource: ISystemStatusCategory[] = [
  {
    title: 'commerceos',
    icon: 'assets/img/platform/os.svg',
    data: [
      'https://commerceos-backend.payever.org/api/status',
      'https://auth.payever.org/api/status',
      'https://users.payever.org/api/status',
      'https://widgets-backend.payever.org/api/status',
    ],
  },
  {
    title: 'checkout',
    icon: 'assets/img/icons/checkout.svg',
    data: ['https://checkout-backend.payever.org/api/status', 'https://checkout-php.payever.org/api/status'],
  },
  {
    title: 'connect',
    icon: 'assets/img/account/plus.svg',
    data: ['https://connect-backend.payever.org/api/status'],
  },
  {
    title: 'contacts',
    icon: 'assets/img/platform/contacts.svg',
    data: ['https://contacts-backend.payever.org/api/status'],
  },
  {
    title: 'mail',
    icon: 'assets/img/platform/mail.svg',
    data: [
      // 'https://marketing-backend.payever.org/api/status',
      'https://mailer.payever.org/api/status',
      'https://mailer-report-backend.payever.org/api/status',
    ],
  },
  {
    title: 'shops',
    icon: 'assets/img/platform/shop.svg',
    data: ['https://shops-backen.payever.org/api/status', 'https://builder-backend.payever.org/api/status'],
  },
  {
    title: 'shipping',
    icon: 'assets/img/platform/shipping.svg',
    data: ['https://shipping-backend.payever.org/api/status'],
  },
  {
    title: 'products',
    icon: 'assets/img/icons/products.svg',
    data: ['https://products-backend.payever.org/api/status', 'https://inventory-backend.payever.org/api/status'],
  },
  {
    title: 'point of sale',
    icon: 'assets/img/icons/pos.svg',
    data: ['https://pos-backend.payever.org/api/status', 'https://device-payments-backend.payever.org/api/status'],
  },
  {
    title: 'transactions',
    icon: 'assets/img/platform/transactions.svg',
    data: ['https://transactions-backend.payever.org/api/status'],
  },
];
