import { NgModule } from '@angular/core';
import { RouterModule, Routes, UrlMatchResult, UrlSegment } from '@angular/router';
import { AccountContainerComponent } from './containers/account-container/account-container.component';
import { LandingContainerComponent } from './containers/landing-container/landing-container.component';
import { MarkdownContainerComponent } from './containers/markdown-container/markdown-container.component';
import { PaymentsContainerComponent } from './containers/payments-container/payments-container.component';
import { PlatformContainerComponent } from './containers/platform-container/platform-container.component';
import { PointOfSaleContainerComponent } from './containers/point-of-sale-container/point-of-sale-container.component';
import { QuickStartContainerComponent } from './containers/quick-start-container/quick-start-container.component';
import { ResourcesContainerComponent } from './containers/resources-container/resources-container.component';
import { ShopsystemsContainerComponent } from './containers/shopsystems-container/shopsystems-container.component';
import { SupportContainerComponent } from './containers/support-container/support-container.component';
import { SystemStatusContainerComponent } from './containers/system-status-container/system-status-container.component';
import { ApiContainerComponent } from './containers/api-container/api-container.component';
import { CountryEnum } from './models/countries.models';

export function categoryMatcher(segments: UrlSegment[]): UrlMatchResult {
  const paramNames = ['category', 'language', 'pageName', 'section', 'subSection', 'h3', 'h4', 'h5'];
  const defaultCountries: string[] = Object.values(CountryEnum);
  const result: UrlMatchResult = { consumed: segments };

  if (!defaultCountries.includes(segments[1].path)) {
    paramNames.splice(1, 1);
  }

  result.posParams = segments.reduce((acc, segment, index) => {
    acc[paramNames[index]] = segment;
    return acc;
  }, {});

  return result;
}

const routes: Routes = [
  {
    path: '',
    component: LandingContainerComponent,
  },
  {
    path: 'quick-start',
    component: QuickStartContainerComponent,
    data: {
      title: 'Quick Start',
    },
  },
  {
    path: 'api',
    component: ApiContainerComponent,
    data: {
      title: 'API',
    },
  },
  {
    path: 'shopsystems',
    component: ShopsystemsContainerComponent,
    data: {
      title: 'Shopsystems',
    },
  },
  {
    path: 'payments',
    component: PaymentsContainerComponent,
    data: {
      title: 'Payments',
    },
  },
  {
    path: 'point-of-sale',
    component: PointOfSaleContainerComponent,
    data: {
      title: 'Point Of Sale',
    },
  },
  {
    path: 'platform',
    component: PlatformContainerComponent,
    data: {
      title: 'Platform',
    },
  },
  {
    path: 'resources',
    component: ResourcesContainerComponent,
    data: {
      title: 'Resources',
    },
  },
  {
    path: 'support',
    component: SupportContainerComponent,
    data: {
      title: 'Support',
    },
  },
  {
    path: 'support/system-status',
    component: SystemStatusContainerComponent,
    data: {
      title: 'System Status',
    },
  },
  {
    path: 'account',
    component: AccountContainerComponent,
    data: {
      title: 'Account',
    },
  },
  {
    path: ':category/api',
    children: [
      { path: ':section', component: MarkdownContainerComponent },
      { path: ':section/:subSection', component: MarkdownContainerComponent },
      { path: ':section/:subSection/:h3', component: MarkdownContainerComponent },
      { path: ':section/:subSection/:h3/:h4', component: MarkdownContainerComponent },
      { path: ':section/:subSection/:h3/:h4/:h5', component: MarkdownContainerComponent },
      { path: '', component: MarkdownContainerComponent },
    ],
    data: {
      accessSectionsByUrl: true,
    },
  },
  {
    path: 'api/payments/v2',
    children: [
      { path: ':section', component: MarkdownContainerComponent },
      { path: ':section/:subSection', component: MarkdownContainerComponent },
      { path: ':section/:subSection/:h3', component: MarkdownContainerComponent },
      { path: ':section/:subSection/:h3/:h4', component: MarkdownContainerComponent },
      { path: ':section/:subSection/:h3/:h4/:h5', component: MarkdownContainerComponent },
      { path: '', component: MarkdownContainerComponent },
    ],
    data: {
      accessSectionsByUrl: true,
    },
  },
  {
    path: 'api/payments/v3',
    children: [
      { path: ':section', component: MarkdownContainerComponent },
      { path: ':section/:subSection', component: MarkdownContainerComponent },
      { path: ':section/:subSection/:h3', component: MarkdownContainerComponent },
      { path: ':section/:subSection/:h3/:h4', component: MarkdownContainerComponent },
      { path: ':section/:subSection/:h3/:h4/:h5', component: MarkdownContainerComponent },
      { path: '', component: MarkdownContainerComponent },
    ],
    data: {
      accessSectionsByUrl: true,
    },
  },
  {
    path: 'api/:category',
    children: [
      { path: ':section', component: MarkdownContainerComponent },
      { path: ':section/:subSection', component: MarkdownContainerComponent },
      { path: ':section/:subSection/:h3', component: MarkdownContainerComponent },
      { path: ':section/:subSection/:h3/:h4', component: MarkdownContainerComponent },
      { path: ':section/:subSection/:h3/:h4/:h5', component: MarkdownContainerComponent },
      { path: '', component: MarkdownContainerComponent },
    ],
    data: {
      accessSectionsByUrl: true,
    },
  },
  {
    matcher: categoryMatcher,
    component: MarkdownContainerComponent,
    data: {
      accessSectionsByUrl: true,
    },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
