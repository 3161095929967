import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DrawerService {
  private isOpenedDrawer$ = new BehaviorSubject<boolean>(false);

  get isOpened$(): Observable<boolean> {
    return this.isOpenedDrawer$;
  }

  public toggle() {
    const currentStatus = this.isOpenedDrawer$.value;
    this.isOpenedDrawer$.next(!currentStatus);
  }
}
