import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ICardBlock } from 'src/app/models/card-blocks.models';
import { CountryEnum } from 'src/app/models/countries.models';
import { CountrySelectorService } from 'src/app/services/country-selector/country-selector.service';

@Component({
  selector: 'app-page-card-block',
  templateUrl: './page-card-block.component.html',
  styleUrls: ['./page-card-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageCardBlockComponent {
  @Input() block: ICardBlock;
  @Input() selectedCountry: CountryEnum;
  public countryEnum = CountryEnum;

  constructor(private countrySelectorService: CountrySelectorService) {}

  get countryKey(): string {
    return this.selectedCountry;
  }
}
