import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ICardBlock } from 'src/app/models/card-blocks.models';
import { ISystemStatusCategory, ISystemStatusResponse } from 'src/app/models/system-status.models';
import { catalogSource } from './system-status.data';

@Injectable({
  providedIn: 'root',
})
export class SystemStatusService {
  private catalogSource: ISystemStatusCategory[] = catalogSource;
  private catalogSource$ = new BehaviorSubject<ISystemStatusCategory[]>(this.catalogSource);

  constructor(private httpClient: HttpClient) {}

  get catalog$(): Observable<ICardBlock[]> {
    return this.catalogSource$.pipe(
      switchMap(items => {
        const items$ = items.map(({ title, data, icon }) => {
          return this.checkCategoryStatus(data).pipe(map(status => ({ title, status, icon, statusDescription: 'April 13th 2022, 2:16PM' })));
        });
        return forkJoin(items$);
      })
    );
  }

  private checkCategoryStatus(data: string[]): Observable<boolean> {
    const endpoints = data.map(endpoint =>
      this.httpClient.get<ISystemStatusResponse>(endpoint).pipe(
        switchMap(response => of(response.status === 'down')),
        catchError(_ => of(false))
      )
    );

    return forkJoin(endpoints).pipe(map(result => result.every(status => status)));
  }
}
