import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ICardBlock } from 'src/app/models/card-blocks.models';
import { CountrySelectorService } from 'src/app/services/country-selector/country-selector.service';
import {Observable, of} from "rxjs";
import {CountryEnum} from "../../models/countries.models";

@Component({
  selector: 'app-page-card-blocks',
  templateUrl: './page-card-blocks.component.html',
  styleUrls: ['./page-card-blocks.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageCardBlocksComponent {
  @Input() blocks: ICardBlock[];
  @Input() withCountrySelector = false;

  constructor(public countrySelector: CountrySelectorService) {}

  get selectedCountry$(): Observable<CountryEnum> {
    return this.withCountrySelector ? this.countrySelector.country$ : of(null);
  }

  showCard(block: ICardBlock) {
    if (!(block.excludedCountries instanceof Array)) {
      return true;
    }

    return block.excludedCountries.indexOf(this.countrySelector.country) === -1;
  }
}
