export const data = [
  {
    timestamp: 1562672765012,
    status: 'ok',
  },
  {
    timestamp: 1562672765012,
    status: 'ok',
  },
  {
    timestamp: 1562672765012,
    status: 'ok',
  },
  {
    timestamp: 1562672765012,
    status: 'ok',
  },
  {
    timestamp: 1562672765012,
    status: 'ok',
  },
  {
    timestamp: 1562672765012,
    status: 'ok',
  },
  {
    timestamp: 1562672765012,
    status: 'ok',
  },
  {
    timestamp: 1562672765012,
    status: 'ok',
  },
  {
    timestamp: 1562672765012,
    status: 'ok',
  },
  {
    timestamp: 1562672765012,
    status: 'ok',
  },
  {
    timestamp: 1562672765012,
    status: 'ok',
  },
  {
    timestamp: 1562672765012,
    status: 'ok',
  },
  {
    timestamp: 1562672765012,
    status: 'ok',
  },
  {
    timestamp: 1562672765012,
    status: 'ok',
  },
  {
    timestamp: 1562672765012,
    status: 'ok',
  },
  {
    timestamp: 1562672765012,
    status: 'ok',
  },
  {
    timestamp: 1562672765012,
    status: 'ok',
  },
  {
    timestamp: 1562672765012,
    status: 'ok',
  },
  {
    timestamp: 1562672765012,
    status: 'ok',
  },
  {
    timestamp: 1562672765012,
    status: 'ok',
  },
  {
    timestamp: 1562672765012,
    status: 'ok',
  },
  {
    timestamp: 1562672765012,
    status: 'ok',
  },
  {
    timestamp: 1562672765012,
    status: 'ok',
  },
  {
    timestamp: 1562672765012,
    status: 'ok',
  },
  {
    timestamp: 1562672765012,
    status: 'ok',
  },
  {
    timestamp: 1562672765012,
    status: 'ok',
  },
  {
    timestamp: 1562672765012,
    status: 'ok',
  },
  {
    timestamp: 1562672765012,
    status: 'ok',
  },
  {
    timestamp: 1562672765012,
    status: 'ok',
  },
  {
    timestamp: 1562672765012,
    status: 'ok',
  },
  {
    timestamp: 1562672765012,
    status: 'ok',
  },
  {
    timestamp: 1562672765012,
    status: 'ok',
  },
  {
    timestamp: 1562672765012,
    status: 'down',
  },
  {
    timestamp: 1562672765012,
    status: 'ok',
  },
  {
    timestamp: 1562672765012,
    status: 'ok',
  },
  {
    timestamp: 1562672765012,
    status: 'ok',
  },
  {
    timestamp: 1562672765012,
    status: 'ok',
  },
  {
    timestamp: 1562672765012,
    status: 'ok',
  },
  {
    timestamp: 1562672765012,
    status: 'down',
  },
  {
    timestamp: 1562672765012,
    status: 'ok',
  },
  {
    timestamp: 1562672765012,
    status: 'down',
  },
];
