import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ICardBlock } from 'src/app/models/card-blocks.models';
import { SystemStatusService } from 'src/app/services/system-status/system-status.service';

@Component({
  selector: 'app-system-status-container',
  templateUrl: './system-status-container.component.html',
  styleUrls: ['./system-status-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SystemStatusContainerComponent implements OnInit {
  public blocks$: Observable<ICardBlock[]>;

  constructor(private systemStatusService: SystemStatusService) {}

  ngOnInit() {
    this.blocks$ = this.systemStatusService.catalog$;
  }
}
