import { ChangeDetectionStrategy, Component } from '@angular/core';

import { ICardBlock } from '../../models/card-blocks.models';
import { IPoint } from "../../models/points.models";
import { CountrySelectorService } from '../../services/country-selector/country-selector.service';

@Component({
  selector: 'app-api-container',
  templateUrl: './api-container.component.html',
  styleUrls: ['./api-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApiContainerComponent {
  heading: string = 'About payever API';
  title: string = 'Start integration now';
  description: string = 'How to connect payever to your third party systems? Start choosing a specific API, and visit the documentation.';

  points: IPoint[] = [
    {
      title: 'Choose API',
      description: 'In order to integrate payever to your business, choose the shopsystem of your choice.',
    },
    {
      title: 'Follow Instructions',
      description: 'Each API has an own integration guide. Follow the instructions to integrate with payever.',
    },
    {
      title: 'Test & Live',
      description: 'Make sure everything works. Insert your API credentials and invoke a test API call.',
    },
  ];

  public links: ICardBlock[] = [
    {
      title: 'Payments V1',
      path: 'payments',
      icon: 'assets/img/api/payv1.svg',
      excludedCountries: [],
      version: 'deprecated'
    },
    {
      title: 'Payments V2',
      path: 'payments/v2',
      icon: 'assets/img/api/payv2.svg',
      excludedCountries: [],
      version: 'current'
    },
    {
      title: 'Payments V3',
      path: 'payments/v3',
      icon: 'assets/img/api/payv3.svg',
      excludedCountries: [],
      version: 'new'
    },
    {
      title: 'Payment Links',
      path: 'links',
      icon: 'assets/img/api/payv1.svg',
      excludedCountries: [],
    },
    {
      title: 'Express Widgets',
      path: 'widgets',
      icon: 'assets/img/api/widget.svg',
      excludedCountries: [],
      version: 'new'
    },
    {
      title: 'Products',
      path: 'products',
      icon: 'assets/img/api/prod.svg',
      excludedCountries: [],
    },
  ];

  constructor(public countrySelector: CountrySelectorService) { }

  ngOnInit() {
    this.countrySelector.updateLanguageSelector(this.links);
  }
}
